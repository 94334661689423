import React from "react"
import { graphql } from "gatsby"
import './MainPages.css'
import sortBy from "lodash/sortBy"
import Link from "gatsby-link"
import Layout from './../components/Layouts';
import { Card, List, Typography } from 'antd';
import './Index.css'
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
deckDeckGoHighlightElement();

export const frontmatter = {
  layoutType: `page`,
  path: `/misc/`,
}


const { Meta } = Card;
// const TITLES = ["Deep Learning", "Visualisation", "Bots"]
  const MakeLink = (props) => {
    return (
      <div>
        {
          props.path.startsWith("https")
            ? <a className="default-link" href={props.path} target="_" rel="noopener noreferrer"><li>{props.title}</li></a>
            : <Link className="default-link" to={props.path}><li>{props.title}</li></Link>
        }
      </div>
    )
  }

class MiscIndex extends React.Component {

  render() {
    let mdEdges = this.props.data.allMarkdownRemark.edges;
    let jsEdges = this.props.data.allJavascriptFrontmatter.edges;
    let allPages = sortBy([...mdEdges, ...jsEdges],
        page => page.node.frontmatter.updated || page.node.frontmatter.written
      ).reverse();

    // console.log(allPages);

    return (
    <Layout data={this.props.data} location={this.props.location}>
    <div className="container" style={{maxWidth: 1200, margin: "40px auto"}}>
        {/* {allPages.map((page, i) => {
            return <p><Link to={page.node.frontmatter.path}><li>{page.node.frontmatter.title}</li></Link></p>
        })
        } */}
        <List
            bordered
            dataSource={allPages}
            renderItem={item => (
                <List.Item>
                    <List.Item.Meta 
                        title={<MakeLink path={item.node.frontmatter.path} title={item.node.frontmatter.title} />}
                        description={item.node.frontmatter.description}
                    />                    
                </List.Item>
            )}
        />
    </div>
    </Layout>
    )
  }
}

export default MiscIndex

export const showAllMiscPageQuery = graphql`
  query showAllMiscArticles {
    allJavascriptFrontmatter(filter: {frontmatter: {contentType: {eq: "misc"}}}, sort: { fields: [frontmatter___updated], order: DESC }) {
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            path
            title
            written
            layoutType
            contentType
            category
            description    
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
              }              
            }
          }
        }
      }
    }
    allMarkdownRemark(filter: {frontmatter: {contentType: {eq: "misc"}}}, sort: { fields: [frontmatter___updated], order: DESC }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            path
            layoutType
            contentType
            written
            updated
            category
            description
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
              }              
            }
          }
          timeToRead
        }
      }
    }
    allDatavizJson {
      edges {
        node {
          title
          path
        }
      }  
    }
  
  }
`
